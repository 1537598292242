import React from "react";
import Box from "@mui/material/Box";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import theme from "../theme";
import { Button } from "@mui/material";

const styles = {
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.palePistachio,
    },
  },
  image: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    width: "60px",
    height: "60px",
    padding: "20px",
    margin: "0 30px",

    "& .MuiSvgIcon-root": {
      width: "100%",
      height: "100%",
      color: theme.palette.black.stoneSilver,
    },
  },
  text: {
    textDecoration: "none",
  },
};
const ClickableApiBox = ({ link, icon, text }) => {
  return (
    <Link to={link} style={styles.text}>
      <Box sx={styles.boxContainer}>
        <Box sx={styles.image}>{icon}</Box>
        <Button variant="text">{text}</Button>
      </Box>
    </Link>
  );
};
ClickableApiBox.propTypes = {
  link: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
};

export default ClickableApiBox;
