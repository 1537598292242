import { Box } from "@mui/material";
import * as React from "react";
import VerticalMenu from "../components/VerticalMenu";
import HorizontalMenu from "../components/HorizontalMenu";
import Container from "@mui/material/Container";
import Helmet from "react-helmet";
import favicon from "../images/symbolLogo/Globhe_symbol_2022_Coolgrey.png";
import AppButton from "../components/AppButton";
import PageTitle from "../components/PageComponents/PageTitle";
import Paragraph from "../components/PageComponents/Paragraph";
import ParagraphTitle from "../components/PageComponents/ParagraphTitle";
import SupportButton from "../components/SupportButton";
import ClickableApiBox from "../components/ClickableApiBox";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import CollectionsIcon from "@mui/icons-material/Collections";
import { pageStyles, pageContent } from "../styleConstants";

const styles = {
  button: {
    marginBottom: "30px",
  },
  iconCollection: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "60px",
  },
};

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <HorizontalMenu />
      <Box sx={pageContent}>
        <VerticalMenu page={0} />

        <Container maxWidth="md" sx={{ wordWrap: "break-word" }}>
          <PageTitle text="GLOBHE's APIs" />
          <Paragraph
            text="Browse user guides, documentation and full API references for GLOBHE's APIs and Integrations. Below are the three APIs currently available.
            Our APIs and integrations makes it easier to
            create pipelines to continuously ingest imagery for processing and
            analysis."
          />
          <ParagraphTitle text="Available APIs" />
          <Box sx={styles.iconCollection}>
            <ClickableApiBox
              icon={<AddCircleOutlineIcon />}
              text="Order API"
              link="/order-api"
            />
            <ClickableApiBox
              icon={<ForwardToInboxIcon />}
              text="Delivery API"
              link="/delivery"
            />
            <ClickableApiBox
              icon={<CollectionsIcon />}
              text="Data library API"
              link="/datalibrary-api"
            />
          </Box>

          <ParagraphTitle text="Get an API Key" />
          <Paragraph
            text="An API-KEY is needed in order to authenticate your requests. All
            registered clients receive an API key automatically after signing
            up. You can find it in your profile page. Sign up as a client to get
            your API key."
          />
          <AppButton
            look="black"
            label="Sign up"
            url="https://app.globhe.com/crowddroning/client/sign-up/"
            externalLink
            targetNewWidow
            addtionalStyle={styles.button}
          />
          <ParagraphTitle text="Need help" />
          <Paragraph
            text="For questions and inquiries, you are more than welcome to contact
            GLOBHE's support."
          />
          <SupportButton />
        </Container>
      </Box>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GLOBHE&apos;s developers docs</title>
        <link rel="icon" href={favicon} />
      </Helmet>
    </main>
  );
};

export default IndexPage;
